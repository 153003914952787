import axios from 'axios';
import { Toast } from 'vant';
/**
 * 获取两位小数点
 * @param {*} val 数值
 * @param {Number} num 小数点
 */
//获取当前日期yy-mm-dd
//date 为时间对象
function getDateStr3(date) {
    var year = "";
    var month = "";
    var day = "";
    var now = date;
    year = "" + now.getFullYear();
    if ((now.getMonth() + 1) < 10) {
        month = "0" + (now.getMonth() + 1);
    } else {
        month = "" + (now.getMonth() + 1);
    }
    if ((now.getDate()) < 10) {
        day = "0" + (now.getDate());
    } else {
        day = "" + (now.getDate());
    }
    return year + "-" + month + "-" + day;
}
/**
 * 获得相对当前周AddWeekCount个周的起止日期
 * AddWeekCount为0代表当前周   为-1代表上一个周   为1代表下一个周以此类推
 * **/
export function getWeekStartAndEnd(AddWeekCount, date) {
    //起止日期数组
    var startStop = new Array();
    //一天的毫秒数
    var millisecond = 1000 * 60 * 60 * 24;
    //获取当前时间
    var currentDate = null;
    if (date) {
        currentDate = new Date(date);
    } else {
        currentDate = new Date();
    }
    //相对于当前日期AddWeekCount个周的日期
    currentDate = new Date(currentDate.getTime() + (millisecond * 7 * AddWeekCount));
    //返回date是一周中的某一天
    var week = currentDate.getDay();
    //返回date是一个月中的某一天
    var month = currentDate.getDate();
    //减去的天数
    var minusDay = week != 0 ? week - 1 : 6;
    //获得当前周的第一天
    var currentWeekFirstDay = new Date(currentDate.getTime() - (millisecond * minusDay));
    //获得当前周的最后一天
    var currentWeekLastDay = new Date(currentWeekFirstDay.getTime() + (millisecond * 6));
    //添加至数组
    startStop.push(getDateStr3(currentWeekFirstDay));
    startStop.push(getDateStr3(currentWeekLastDay));
    return startStop;
}
/**
 * 获得相对当月AddMonthCount个月的起止日期
 * AddMonthCount为0 代表当月 为-1代表上一个月  为1代表下一个月 以此类推
 * date 不传默认当月，传 '2021-03-01' 则返回当月起止日期
 * ***/
export function getMonthStartAndEnd(AddMonthCount, date) {
    //起止日期数组
    var startStop = new Array();
    //获取当前时间
    var currentDate = null;
    if (date) {
        currentDate = new Date(date);
    } else {
        currentDate = new Date();
    }
    var month = currentDate.getMonth() + AddMonthCount;
    if (month < 0) {
        var n = parseInt((-month) / 12);
        month += n * 12;
        currentDate.setFullYear(currentDate.getFullYear() - n);
    }
    currentDate = new Date(currentDate.setMonth(month));
    //获得当前月份0-11
    var currentMonth = currentDate.getMonth();
    //获得当前年份4位年
    var currentYear = currentDate.getFullYear();
    //获得上一个月的第一天
    var currentMonthFirstDay = new Date(currentYear, currentMonth, 1);
    //获得上一月的最后一天
    var currentMonthLastDay = new Date(currentYear, currentMonth + 1, 0);
    //添加至数组
    startStop.push(getDateStr3(currentMonthFirstDay));
    startStop.push(getDateStr3(currentMonthLastDay));
    //返回
    return startStop;
}
export function getTofixed(val, num) {
    return parseFloat(val).toFixed(num);
}
// 在线医生实时签名接口
export function generateSign() {
    console.log(localStorage.getItem('userData'))
    let loginInfo = JSON.parse(localStorage.getItem('userData')) || '';
    console.log('loginInfo', loginInfo)
    let params = {
        memberId: loginInfo.memberId
    }
    console.log('this===>', this)
    axios.get('/server/sign/getSignInfo', { params: params }).then(res => {
        // axios.get('/dev_api/server/sign/getSignInfo', { params: params }).then(res => {
        console.log('签名', res);
        if (res.data.code == 200) {
            loginInfo.timestamp = res.data.data.timestamp;
            loginInfo.sign = res.data.data.sign;
            localStorage.setItem('userData', JSON.stringify(loginInfo))
            console.log(loginInfo);
        } else {
            Toast(res.data.msg);
        }
    })
}
// 手机号验证
export function validPhone(phone) {
    const flag = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    return flag.test(phone)
    // if (!flag.test(phone)) {
    //     Toast('请输入正确的手机号');
    //     return false;
    // }
}
/**
 * 获取url后面的参数值
 * @param variable 想得到的参数名
 *
 */
export function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}
/**
 * 时间格式转化---date(2020-02-11T12:24:18转为正常时间格式2020-02-11 12:24:18)
 */
export function renderTime(date) {
    var dateee = new Date(date).toJSON();
    return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
}
//  仿微信好友列表（昨天，前天，周，年月日） data(2020-02-12 14:23)
function formatTime2(date) {
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    var hour = date.getHours()
    var minute = date.getMinutes()
    var second = date.getSeconds()
    return [year, month, day].map(formatNumber2).join('/') + ' ' + [hour, minute, second].map(formatNumber2).join(
        ':')
}

function formatNumber2(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
}
export function getTimeText(data) {
    // 时间处理 主要根据当前时间的凌晨和当前时间周一的凌晨 做判断依据 （时间都转化成时间戳好计算，自认为）
    var time = formatTime2(new Date());
    console.log('time===>', time)
    let date = time.replace(/\//g, "/")
    var date1 = new Date(date) // 现在的时间
    var lc = new Date().setHours(0, 0, 0, 0) // 当前时间的凌晨的时间戳
    var nowlc = new Date(lc);
    var nowTime = nowlc.getTime();
    var day = nowlc.getDay();
    var oneDay = 24 * 60 * 60 * 1000;
    var Monday = nowTime - (day - 1) * oneDay;
    var monday = new Date(Monday);
    var mondaytamp = Number(monday); //当前时间周一凌晨的时间戳
    var date2 = new Date(data)
    var timetamp = Number(new Date(data)); // 转化从时间戳
    let d = ((lc - date2) / 1000) // 两个时间相差的秒数
    if (d > 0) {
        var min = (d / 60).toFixed(0)
        var h = (min / 60).toFixed(0)
        // console.log(h)
        if (h <= 24) {
            return data = "昨天" + data.slice(10, 16)
        } else if (h <= 48) {
            return data = "前天" + data.slice(10, 16)
        } else if (h > 48) {
            let d = timetamp - mondaytamp
            if (d >= 0) {  //本周
                var l = ["日", "一", "二", "三", "四", "五", "六"];
                let z = date2.getDay()
                return data = "周" + l[z] + data.slice(10, 16)
            } else if (d < 0) { //上周
                let y = date2.getFullYear()
                let m = date2.getMonth()
                let d = date2.getDate()
                return data = y + "年" + (m + 1) + "月" + d + "日"
            }
        }
    } else {
        // 当时时间段  时分
        let h = date2.getHours()
        let m = date2.getMinutes()
        return data = h + ":" + m
    }
}
/**
 * 获取身份证其他信息
 * @param {*} val 身份证号码长度
 * @param {*} val 身份证号码长度
 */
export function getIdCardInfo(val, idCardNo) {
    let iden = idCardNo;
    let sex = null; //1是男，2是女
    let birth = null;
    let myDate = new Date();
    let month = myDate.getMonth() + 1;
    let day = myDate.getDate();
    let age = 0;
    const _AREA = {
        11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江",
        31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北",
        43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏",
        61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外"
    };

    if (val === 18) {
        age = myDate.getFullYear() - iden.substring(6, 10) - 1;
        sex = iden.substring(16, 17);
        birth = iden.substring(6, 10) + "-" + iden.substring(10, 12) + "-" + iden.substring(12, 14);
        if (iden.substring(10, 12) < month || iden.substring(10, 12) == month && iden.substring(12, 14) <= day) age++;

    }
    if (val === 15) {
        age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
        sex = iden.substring(13, 14);
        birth = "19" + iden.substring(6, 8) + "-" + iden.substring(8, 10) + "-" + iden.substring(10, 12);
        if (iden.substring(8, 10) < month || iden.substring(8, 10) == month && iden.substring(10, 12) <= day) age++;
    }
    sex = sex % 2 === 0 ? 2 : 1;
    return {
        sex: sex,
        age: age,
        birthday: birth,
        birthplace: _AREA[iden.substring(0, 2)],
    }
}

// 根据出生日期算年龄 strAge ====> 出生日期-2020-02-12
export function getAge(strAge) {
    var birArr = strAge.split("-");
    var birYear = birArr[0];
    var birMonth = birArr[1];
    var birDay = birArr[2];

    d = new Date();
    var nowYear = d.getFullYear();
    var nowMonth = d.getMonth() + 1; //记得加1
    var nowDay = d.getDate();
    var returnAge;

    if (birArr == null) {
        return false
    };
    var d = new Date(birYear, birMonth - 1, birDay);
    if (d.getFullYear() == birYear && (d.getMonth() + 1) == birMonth && d.getDate() == birDay) {
        if (nowYear == birYear) {
            returnAge = 0; //
        } else {
            var ageDiff = nowYear - birYear; //
            if (ageDiff > 0) {
                if (nowMonth == birMonth) {
                    var dayDiff = nowDay - birDay; //
                    if (dayDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                } else {
                    var monthDiff = nowMonth - birMonth; //
                    if (monthDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                }
            } else {
                return "出生日期晚于今天，数据有误"; //返回-1 表示出生日期输入错误 晚于今天
            }
        }
        return returnAge;
    } else {
        return ("输入的日期格式错误！");
    }
}
/**
 * 数组赋值浅拷贝
 * @param {Array} list 数组
 */
export function getNewArrData(list) {
    return JSON.parse(JSON.stringify(list)); //this.templateData是父组件传递的对象
}
/**
 * @param {*} unit 传空-默认格式返回yyyy-MM-dd HH-mm-ss， ‘cn’:年月日 （‘-’，‘.’）…
 * @param {*} date 时间戳，或者传空默认获取当天
 * @param {*} isNeedHms 是否需要时分秒 true需要 false不需要
 */
export function getDate(unit, date, isNeedHms) {
    var d = null;
    if (date) {
        d = new Date(date);
    } else {
        d = new Date();
    }
    var year = d.getFullYear();//得到年
    var month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1);//得到月
    var day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();//得到日
    var hour = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();//得到时
    var minute = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();//得到分
    var second = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();//得到秒
    if (!isNeedHms) {
        if (unit == 'cn') {
            return `${year}年${month}月${day}日`;
        } else if (unit == 'month') {
            return `${year}-${month}`
        } else {
            return [year, month, day].join("-");//格式为：YY-MM-DD hh:mm:ss（当前时间）
        }
    } else {
        if (unit == 'cn') {
            return `${year}年${month}月${day}日 ${hour}时:${minute}:${second}`;
        } else {
            return [year, month, day].join(unit) + " " + [hour, minute, second].join(':');//格式为：YY-MM-DD hh:mm:ss（当前时间）
        }
    }
}
/**
   * 获取下一个月
   *
   * @date 格式为yyyy-mm-dd的日期，如：2014-01-25
*/
export function getPreMonth(date) {
    var arr = date.split('-');
    var year = arr[0]; //获取当前日期的年份
    var month = arr[1]; //获取当前日期的月份
    var day = arr[2]; //获取当前日期的日
    var days = new Date(year, month, 0);
    days = days.getDate(); //获取当前日期中月的天数
    var year2 = year;
    var month2 = parseInt(month) + 1;
    if (month2 == 13) {
        year2 = parseInt(year2) + 1;
        month2 = 1;
    }
    var day2 = day;
    var days2 = new Date(year2, month2, 0);
    days2 = days2.getDate();
    if (day2 > days2) {
        day2 = days2;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }
    var t2 = year2 + '-' + month2 + '-' + day2;
    return t2;
}

export function rotateBase64Img(src, edg, callback) {

    var __canvas = document.createElement("canvas");
    __canvas.setAttribute('id', 'imgCanvasR');
    var __ctx = __canvas.getContext("2d");

    var imgW;//图片宽度
    var imgH;//图片高度
    var size;//canvas初始大小

    if (edg % 90 != 0) {
        console.error("旋转角度必须是90的倍数!");
        throw '旋转角度必须是90的倍数!';
    }
    (edg < 0) && (edg = (edg % 360) + 360)
    const quadrant = (edg / 90) % 4; //旋转象限
    const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 }; //裁剪坐标

    var image = new Image();
    image.crossOrigin = "anonymous"

    image.onload = function () {

        imgW = image.width;
        imgH = image.height;
        size = imgW > imgH ? imgW : imgH;

        __canvas.width = size * 2;
        __canvas.height = size * 2;
        switch (quadrant) {
            case 0:
                cutCoor.sx = size;
                cutCoor.sy = size;
                cutCoor.ex = size + imgW;
                cutCoor.ey = size + imgH;
                break;
            case 1:
                cutCoor.sx = size - imgH;
                cutCoor.sy = size;
                cutCoor.ex = size;
                cutCoor.ey = size + imgW;
                break;
            case 2:
                cutCoor.sx = size - imgW;
                cutCoor.sy = size - imgH;
                cutCoor.ex = size;
                cutCoor.ey = size;
                break;
            case 3:
                cutCoor.sx = size;
                cutCoor.sy = size - imgW;
                cutCoor.ex = size + imgH;
                cutCoor.ey = size + imgW;
                break;
        }


        __ctx.translate(size, size);
        __ctx.rotate(edg * Math.PI / 180);
        __ctx.drawImage(image, 0, 0);

        var imgData = __ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
        if (quadrant % 2 == 0) {
            __canvas.width = imgW;
            __canvas.height = imgH;
        } else {
            __canvas.width = imgH;
            __canvas.height = imgW;
        }
        __ctx.putImageData(imgData, 0, 0);
        callback(__canvas.toDataURL('image/png', 1));
    };

    image.src = src;
}
